import API from "@/api";

export default {
  choices: params => {
    return API.requestGet(
      { params, url: "/api/merchant/pay/rechargeSetting" },
      false
    );
  },
  pay: params => {
    return API.requestGet(
      {
        url: "/api/merchant/pay/payOrder",
        params,
        auth: true
      },
      false
    )
  }
}
